exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-apropos-jsx": () => import("./../../../src/pages/apropos.jsx" /* webpackChunkName: "component---src-pages-apropos-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-catalogue-jsx": () => import("./../../../src/pages/catalogue.jsx" /* webpackChunkName: "component---src-pages-catalogue-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-reparation-jsx": () => import("./../../../src/pages/reparation.jsx" /* webpackChunkName: "component---src-pages-reparation-jsx" */),
  "component---src-pages-reservation-jsx": () => import("./../../../src/pages/reservation.jsx" /* webpackChunkName: "component---src-pages-reservation-jsx" */)
}

